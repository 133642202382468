<template>
  <label @click="input.click()">
    <template name="button">
      <el-button type="primary" size="mini">点击上传 </el-button>
    </template>
  </label>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    type: {
      type: String,
      default: "RESUME",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  watch: {},
  mounted() {
    this.input = document.createElement("input");
    this.input.setAttribute("type", "file");
    if (this.multiple) {
      this.input.setAttribute("multiple", "multiple");
    }
    this.input.addEventListener("change", (e) => {
      var files = e.target.files;
      var form = new FormData();
      form.append("file", files[0]);
      form.append("type", this.type);
      this.$api.common
        .uploadFile(form)
        .then((res) => {
          // console.log({ resumeName: res.data.fileName, resumePath: res.data.filePath });
          this.$emit("upload", { resumeName: res.data.fileName, resumePath: res.data.filePath });
          this.input.value = "";
        })
        .catch((err) => {
          this.input.value = "";
        })
    });
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
